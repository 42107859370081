<script setup lang="ts">
import mime from 'mime'
import { humanFileSize } from '~/lib'

interface DownloadItem {
  title: string
  type?: string
  size?: string | number
  url: string
}
const props = defineProps<{
  item: DownloadItem
}>()

const item = {
  title: props.item.title,
  type: props.item.type ? mime.getExtension(props.item.type) : undefined,
  size: props.item.size ? humanFileSize(+props.item.size, 2) : undefined,
  url: props.item.url,
}
</script>
<template>
  <div v-if="item.url" class="item">
    <div class="content">
      <h4 v-if="item.title" class="title">{{ item.title }}</h4>
      <div class="file text-small">
        <span v-if="item.type" class="file-type">{{ item.type }}</span>
        <span v-if="item.size" class="file-size"> ({{ item.size }}) </span>
      </div>
    </div>
    <Button secondary icon="download" :to="item.url">{{ $t('download.button') }}</Button>
  </div>
</template>
<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 18px;
  padding-block: 24px;
  border-bottom: 1px solid var(--c-line-medium);

  @include breakpoint(ts) {
    grid-template-columns: 1fr auto;
    gap: 24px;
  }
}

.title {
  margin-bottom: 0;
}

.file {
  color: var(--c-text-medium);

  display: flex;
  align-items: center;
  gap: 5px;

  text-transform: uppercase;

  .file-size {
    display: none;

    @include breakpoint(ds) {
      display: block;
    }
  }
}
</style>
